import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import { FormattedMessage } from 'react-intl'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import { IntlProvider } from 'contexts/intl/IntlContext'
import PageTitle from 'components/PageTitle'

const PrivacyPolicy = ({ location }) => (
  <IntlProvider pathname={location.pathname}>
    {({ locale, formatMessage, getPath }) => (
      <Layout>
        <SEO
          lang={locale}
          title={formatMessage({ id: 'privacy.policy.title' })}
          description={formatMessage({ id: 'meta.description' })}
          keywords={formatMessage({ id: 'meta.keywords' })}
          url={formatMessage({ id: 'meta.url.privacy.policy' })}
          isBlogPost={false}
          ogTitle={formatMessage({ id: 'privacy.policy.title' })}
          ogUrl={formatMessage({ id: 'meta.url.privacy.policy' })}
          ogDescription={formatMessage({ id: 'meta.description' })}
          ogKeywords={formatMessage({ id: 'meta.keywords' })}
          ogLocale={locale}
          twitterTitle={formatMessage({ id: 'privacy.policy.title' })}
          twitterDescription={formatMessage({ id: 'meta.description' })}
          twitterCreator={'Mihir Pipermitwala'}
          siteName={'Mihir Pipermitwala'}
        />
        <section className='section is-size-5-desktop is-size-6-touch'>
          <Helmet
            script={[
              {
                async: true,
                src: 'https://cdn1.stackshare.io/javascripts/client-code.js',
                charSet: 'utf-8',
              },
            ]}
          />
          <PageTitle pageTitle={<FormattedMessage id='privacy.policy.title' />} />
          <div className='container '>
            <div className='columns mt-6'>
              <div className='column'>
                <p>
                  Your privacy is very important to us. This Privacy Policy outlines how we collect, use, and protect
                  your personal information when you visit our website.
                </p>
                <h4 className='title is-4 mt-6'>Information Collection</h4>
                <p>
                  Our website collects basic information about visitors, including IP addresses, browser details,
                  timestamps, and referring pages. This data is non-personally identifiable and is used for routine
                  website administration and maintenance purposes.
                </p>
                <h4 className='title is-4 mt-6'>Cookies</h4>
                <p>
                  We use cookies to store information about your preferences and browsing history to enhance your
                  experience on our website. Cookies help us deliver customized content and improve our services.
                </p>
                <h4 className='title is-4 mt-6'>Third-Party Advertisements and Analytics</h4>
                <p>
                  We partner with third-party services, including Google Analytics and Facebook Pixel, which may use
                  cookies, scripts, and web beacons to track your interactions with our website. This data is used to
                  display relevant advertisements and analyze website performance. These third-party services manage
                  their tracking technologies independently, and their usage is governed by their respective privacy
                  policies. You can opt out of Google’s cookie usage through their {''}
                  <a href='http://www.google.com/privacy_ads.html'>settings</a>.
                </p>
                <h4 className='title is-4 mt-6'>External Links</h4>
                <p>
                  Our website may contain links to third-party websites. We are not responsible for the privacy
                  practices of these external sites. We encourage you to review the privacy policies of any third-party
                  websites you visit.
                </p>
                <h4 className='title is-4 mt-6'>Security</h4>
                <p>
                  We take the security of your personal information seriously. However, please note that no method of
                  internet transmission or electronic storage is completely secure. While we strive to use commercially
                  acceptable measures to protect your personal data, we cannot guarantee its absolute security.
                </p>
                <h4 className='title is-4 mt-6'>Changes To This Privacy Policy</h4>
                <p>
                  This Privacy Policy is effective as of the date posted and will remain in effect until any changes are
                  made. Any modifications to the policy will take effect immediately upon being posted on this page. We
                  reserve the right to update or change this policy at any time, and we recommend you review it
                  periodically. If there are significant changes, we will notify you either through the email address
                  you provided or by prominently displaying a notice on our website.
                </p>
                <h4 className='title is-4 mt-6'>Contact Information</h4>
                <p>
                  For any questions or concerns regarding the privacy policy, please email at
                  <a className='button is-ghost has-text-white' href='mailto:mihirpipermitwala@gmail.com'>
                    mihirpipermitwala@gmail.com
                  </a>
                </p>
              </div>
            </div>
            <p className='has-text-centered'>
              <FormattedMessage id='checkOut' />{' '}
              <Link to={getPath('blogs')}>
                <FormattedMessage id='blogs.title' />
              </Link>
              {'.'}
            </p>
          </div>
        </section>
      </Layout>
    )}
  </IntlProvider>
)

PrivacyPolicy.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default PrivacyPolicy
